<template>
    <b-modal
        id="modal-add-contract"
        :title="i18nT(`Ajouter un nouveau`)"
        :ok-title="i18nT(`Sauvegarder`)"
        :cancel-title="i18nT(`Annuler`)"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @ok="saveNewContract"
        @hidden="resetForm"
        @shown="filterContracts"
        size="lg"
    >
        <validation-observer ref="simpleRules">
            <b-card no-body>
                <b-row class=" mr-1 ml-1 mb-2 pb-2">
                    <b-col cols="12"
                           md="12"
                           class="pt-2"
                    >
                        <b-row>
                            <b-col cols="12"
                                   md="8"
                            >
                                <b-form-group
                                    :label="i18nT(`Type`)"
                                    class="required"
                                >
                                    <validation-provider
                                        :name="i18nT(`Type`)"
                                        rules="required"
                                        #default="{ errors }"
                                    >
                                        <v-select
                                            v-model="currentContract.PropertiesContractType"
                                            :options="contractTypes"
                                            label="text"
                                            :clearable="false"
                                            :reduce="item=>+item.value"
                                            :placeholder="i18nT(`Choisir`)"
                                            :class="{ 'border-invalid': errors.length > 0 }"
                                        />
                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-form-checkbox
                                    name="check-button"
                                    ref="existingContract"
                                    switch
                                    v-model="chooseContract"
                                >
                                    {{ i18nT(`Choisir un document existant`) }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <div v-if="!chooseContract" class="mt-1">
                            <b-row>
                                <b-col cols="12" md="8">
                                    <div class='pb-1'>
                                        <!--                      TODO add methods for file upload-->
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            class="mt-0 mt-1 mr-1 mb-0"
                                            variant="outline-primary"
                                            @click="onFilePick"
                                        >
                                            <rt-icon icon="circle-plus" size="16" variant="light"/>
                                            {{ i18nT(`Parcourir`) }}
                                        </b-button>
                                        <b-form-file
                                            ref="landlord-contract"
                                            v-model="newContractFile"
                                            class="hidden"
                                        />
                                        <br/>
                                        <span class="pb-0 mb-0 mt-1" v-if="newContractFile || newContractFileName">
                                  <b-link v-if="newContractFileURL" :href="newContractFileURL" class="text-secondary">
                                      {{ newContractFileName }}
                                  </b-link>
                                  <span v-else>
                                      {{ newContractFileName }}
                                  </span>
                                  <span @click.prevent="onFileDelete" class="cursor-pointer ml-50"><rt-icon icon="trash"
                                                                                                            variant="light text-danger"
                                                                                                            :size="16"/></span>
                              </span>
                                        <br/>
                                        <small class="mb-1">
                                            {{
                                                i18nT(`Formats acceptés: Word, Excel, PDF, Images (GIF, JPG, PNG)`)
                                            }}<br/>
                                            {{ i18nT(`Taille maximale: 15 Mo`) }}
                                        </small>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="chooseContract" class="mt-1">
                            <b-row>
                                <b-col cols="12" md="8">
                                    <b-form-group
                                    >
                                        <v-select
                                            v-model="existingContract"
                                            :options="allDocuments"
                                            label="text"
                                            :clearable="false"
                                            :reduce="item=>item.value"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <b-row v-if="currentContract.PropertiesContractType=='3'">
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Dépenses annuelles`)"
                                >
                                    <div class="d-flex">
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="currentContract.PropertyEnergyConsumptionAmountFrom"
                                                class="w-50 mr-50 border-left-none"
                                                :placeholder="i18nT(`De`)"
                                            />
                                        </b-input-group>
                                        <b-input-group
                                            prepend="EUR"
                                            class="border-right-none"
                                        >
                                            <b-form-input
                                                v-model="currentContract.PropertyEnergyConsumptionAmountTo"
                                                class="w-50 border-left-none"
                                                :placeholder="i18nT(`A`)"

                                            />
                                        </b-input-group>
                                    </div>
                                    <small>{{ i18nT(`Montant estimé des depenses annuelles d'énergie pour un usage standard.`) }}</small>
                                </b-form-group>

                            </b-col>
                        </b-row>
                        <b-row v-if="currentContract.PropertiesContractType=='3'">
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Consommations énergétiques`)"
                                >
                                    <div class="d-flex">
                                        <b-form-input
                                            type="number"
                                            v-model="currentContract.PropertyEnergyConsumption"
                                            class="w-50"
                                            placeholder="Ex.100"
                                        />
                                        <v-select
                                            v-model="currentContract.PropertyEnergyConsumptionIndex"
                                            :options="grades"
                                            label="text"
                                            :clearable="false"
                                            :reduce="option => option.value"
                                            class="w-50 ml-1"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="currentContract.PropertiesContractType=='3'">
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Emissions de gaz à effet de serre`)"
                                >
                                    <div class="d-flex">
                                        <b-form-input
                                            v-model="currentContract.PropertyGreenhouse"
                                            class="w-50"
                                            placeholder="Ex.100"

                                        />
                                        <v-select
                                            v-model="gasEmission.PropertyGreenhouseIndex"
                                            :options="grades"
                                            label="text"
                                            :clearable="false"
                                            class="w-50 ml-1"
                                            :reduce="item=>item.value"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="currentContract.PropertiesContractType !='3'">
                            <b-col cols="12"
                                   md="12"
                            >
                                <b-form-group
                                    :label="i18nT(`Description`)"
                                    class="required"
                                >
                                    <validation-provider
                                        :name="i18nT(`Description`)"
                                        rules="required"
                                        #default="{ errors }"
                                    >
                                        <b-form-textarea
                                            :id='i18nT(`Description`)'
                                            v-model="currentContract.PropertiesContractText"
                                            :placeholder="i18nT(`Description, type, société, durée, autres informations ...`)"
                                            :rows="4"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Date d'établissement`)"
                                >
                                    <date-picker
                                        v-model="currentContract.PropertiesContractDate"
                                        reset-button
                                        :manual-input="true"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="currentContract.PropertiesContractType < 16">
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Date d'échéance`)"
                                >
                                    <date-picker
                                        v-model="currentContract.PropertiesContractDateTo"
                                        reset-button
                                        :manual-input="true"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </validation-observer>
    </b-modal>
</template>

<script>

import {
    BCard,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BModal,
    BRow,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import DatePicker from "@core/components/DatePicker.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import { required } from '@validations'
export default {

    components: {
        DatePicker,
        BRow,
        BCard,
        BFormCheckbox,
        BCol,
        BFormGroup,
        vSelect,
        BModal,
        ValidationObserver,
        ValidationProvider,
    },
    directives: {
        Ripple
    },
    props: {
        contracts: {
            type: Array,
            default: []
        },
        contract: {
            type: Object,
            default: null
        },
        allContractTypes: {
            type: Array,
            default: []
        },
        documents: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            required,
            currentContract: {},
            newContractFile: null,
            newContractFileName: '',
            newContractFileURL: '',
            chooseContract:false,
            existingContract: {
                value: '-1',
                text: this.i18nT('Choisir')
            },
            existingContracts: [],
            allDocuments: [],
            contractTypes: [],
            contractType: {},
            diagnostics: true,
            electricalConsumption:{
                amount: '',
                grade: '',
            },
            grades: [
                {value: '-1', text: 'Choisir'},
                {value: 'A', text: 'A'},
                {value: 'B', text: 'B'},
                {value: 'C', text: 'C'},
                {value: 'D', text: 'D'},
                {value: 'E', text: 'E'},
                {value: 'F', text: 'F'},
                {value: 'G', text: 'G'},
            ],
            annualExpense: {
                min: '',
                max: '',
            },
            gasEmission:{
                amount: '',
                grade: '',
            },
            isDataFetched: false,
        }
    },
    created() {



    },
    methods: {
        filterContracts(){
            let existingContracts = this.contracts.map(contract=> contract.PropertiesContractType)
            if(this.contract){
                existingContracts = existingContracts.filter(contractTypeID=> contractTypeID != this.contract.PropertiesContractType);
            }
            this.contractTypes = this.allContractTypes.filter(contract => !existingContracts.includes(contract.value))
        },
        onFilePick() {
            this.$refs['landlord-contract'].$refs.input.click()
        },
        resetForm(){
            this.newContractFile = null;
            this.newContractFileName = '';
            this.newContractFileURL = '';
            this.existingContract = false;
            this.currentContract = {};
            this.$emit('reset')
        },
        saveNewContract(){
            console.log('save new document') //TODO add method for saving new document
        },
        onFileDelete() {
            this.newContractFile = null;
            this.newContractFileName = '';
            this.newContractFileURL = '';
        }

    },
    watch: {
        contracts: {
            handler: function (val) {
                this.existingContracts = val.map(document=>{
                    return {
                        value: document.DocumentID,
                        text: document.DocumentName
                    }
                })
                this.existingContracts.unshift({
                    value: '-1',
                    text: this.i18nT('Choisir')
                });
            },
            deep: true
        },
        newContractFile: function(val) {
            if (val) {
                this.newContractFileName = val.name
                this.newContractFile = val
                this.newContractFileURL = URL.createObjectURL(val)
            } else{
                this.newContractFileName = ''
                this.newContractFile = null
                this.newContractFileURL = ''

            }
        },
        contract: {
            handler: function(val){
                if(val){
                    this.currentContract = {...val};
                }
            },
            deep: true
        },
        documents: {
            handler: function (val) {
                this.allDocuments = val.map(document=>{
                    return {
                        value: document.id,
                        text: document.label
                    }
                })
                this.allDocuments.unshift({
                    value: '-1',
                    text: this.i18nT('Choisir')
                });
            },
            deep: true
        }
    }
}

</script>

<style class="scss">

.border-right-none{
    border-right: none;
}
.border-left-none{
    border-left: none;
}

</style>