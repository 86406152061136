<template>
    <div>
        <ellipsis-dropdown 
            componentName="properties"
            :showDeleteBtn="curUnitId !== undefined"
            @save="validationForm"
            @delete="onDelete"
        />
        <validation-observer ref="simpleRules">
            <b-tabs
                v-model="tabIndex"
                vertical
                content-class="col-12 col-md-9 mt-1 mt-md-0"
                pills
                no-nav-style
                nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left"
            >

<!--                    GENERAL INFO-->
                <b-tab
                    @click="changeTab('general-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="circle-info"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations générales`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Informations générales`)}}</h2>
                        </b-card-header>
                        <b-row class="m-1">
                            <b-col
                                cols="12"
                                class="my-1"
                            >
                                <b-row>
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Devise`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Devise`)"
                                            >
                                                <v-select
                                                    v-model="unitData.PropertyCurrency"
                                                    :options="currencyOptions"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => e.value"
                                                    :placeholder="i18nT(`Choisir`)"

                                                />
                                            </validation-provider>
                                            <p class="pt-50">{{ i18nT(`Choisir la devise si différente de celle du compte.`) }}  (€)</p>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations générales`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row class="mt-3">
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Propriétaire`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`landlords`)"
                                            >
                                                <v-select
                                                    v-model="unitData.MLandlordID"
                                                    :options="landlordOptions"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => +(e.value)"
                                                    class="mb-1 required"
                                                    :placeholder="i18nT(`Choisir`)"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Type`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`type`)"
                                            >
                                                <v-select
                                                    v-model="unitData.PropertyTypeID"
                                                    :options="typeOptions"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => +(e.value)"
                                                    class="mb-1 required"
                                                    :placeholder="i18nT(`Choisir`)"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Identifiant`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Identifiant`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    :id="i18nT(`Identifiant`)"
                                                    v-model="unitData.PropertyTitle"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                            <p class="pt-50">{{i18nT(`Saisir un identifiant, référence ou numéro unique.`)}}</p>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1">
                            <b-col
                                cols="12"
                                md="2"
                            >
                                <b-form-group
                                    :label="i18nT(`Couleur`)"
                                    class="pl-0"
                                >
                                    <b-form-input
                                        min="0"
                                        type="color"
                                        v-model="unitData.PropertyAvatarHexColor"
                                        :placeholder="i18nT(`E.g. #C1B2A3`)"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row class="mt-3">
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                            class="pl-0 required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Adresse`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >

                                                <b-form-input
                                                    :id="i18nT(`Adresse`)"
                                                    v-model="unitData.PropertyAddress"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                            class="pl-0"
                                        >
                                                <b-form-input
                                                    v-model="unitData.PropertyAddress2"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Bâtiment`)"
                                            class="pl-0"
                                        >
                                                <b-form-input
                                                    v-model="unitData.PropertyBatiment"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Escalier`)"
                                            class="pl-0"
                                        >
                                                <b-form-input
                                                    v-model="unitData.PropertyEscalier"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Étage`)"
                                            class="pl-0"
                                        >
                                                <b-form-input
                                                    v-model="unitData.PropertyFloor"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Numero`)"
                                            class="pl-0"
                                        >
                                                <b-form-input
                                                    v-model="unitData.PropertyDoorNum"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                            class="pl-0 required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Ville`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >

                                                <b-form-input
                                                    :id="i18nT(`Ville`)"
                                                    v-model="unitData.PropertyCity"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                            class="pl-0 required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Code postal`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >

                                                <b-form-input
                                                    :id="i18nT(`Code postal`)"
                                                    v-model="unitData.PropertyPostalCode"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                            class="pl-0"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyState"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Pays`)"
                                                rules="required"
                                            >
                                                <v-select
                                                    :id="i18nT(`Pays`)"
                                                    :options="countries"
                                                    v-model="unitData.PropertyCountry"
                                                    label="text"
                                                    :reduce="(e) => e.value"
                                                    :clearable="false"
                                                    :class="{ 'border-invalid': errors.length > 0 }"
                                                    :placeholder="i18nT(`Choisir`)"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations locatives`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row class="mt-3">
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Type de location proposé`)"
                                        >
                                                <v-select
                                                    v-model="unitData.PropertyRentType"
                                                    :options="rentTypes"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => e.value"
                                                    :placeholder="i18nT(`Choisir`)"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Loyer hors charges`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyRent"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                                <p class="pt-50 mb-0">{{i18nT(`Montant repris à titre indicatif dans la page de création de location et la fiche produit.`)}}</p>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Charges`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyMaintenance"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                                <p class="pt-50 mb-0">{{i18nT(`Montant repris à titre indicatif dans la page de création de location et la fiche produit.`)}}</p>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Description`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12" md="12">
                                <b-row class="mt-3">
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Superficie m²`)"
                                        >
                                            <b-form-input
                                                type="number"
                                                v-model="unitData.PropertySize"
                                                placeholder="0"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Nombre de pièces`)"
                                        >
                                            <b-form-input
                                                type="number"
                                                v-model="unitData.PropertyRoomsNum"
                                                placeholder="0"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Nombre de chambres`)"
                                        >
                                            <b-form-input
                                                type="number"
                                                v-model="unitData.PropertyRoomsNumChambres"
                                                placeholder="0"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Salles de bain`)"
                                        >
                                            <b-form-input
                                                type="number"
                                                v-model="unitData.PropertyRoomsNumBaths"
                                                placeholder="0"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Date de construction`)"
                                        >
                                            <date-picker
                                                v-model="unitData.PropertyConstructionDate"
                                                :manual-input = "true"
                                                reset-button
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Description`)"
                                        >
                                            <b-form-textarea
                                                id='Property comment'
                                                v-model="unitData.PropertyComments"
                                                :placeholder="i18nT(`Ex. Studio meublé tout confort au 3ème étage sur cour d'un immeuble charmant...`)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Note privée`)"
                                        >
                                            <b-form-textarea
                                                id='Property comment new'
                                                v-model="unitData.PropertyCommentsNew"
                                                :placeholder="i18nT(`Autres informations`)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                    ADDITIONAL INFO-->

                <b-tab
                    @click="changeTab('additional-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="sitemap"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations complémentaires`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Informations complémentaires`)}}</h2>
                        </b-card-header>
                        <b-row class="mt-1 mx-1 mb-2 pb-2">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row >
                                    <b-col
                                        cols="12"
                                    >
                                            <b-form-radio-group
                                                class="mt-1 pr-1 ren-radio-type-buttons d-flex w-auto flex-column flex-lg-row"
                                                v-model="unitData.PropertyTypeHabitat"
                                            >
                                                    <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': unitData.PropertyTypeHabitat =='1'}"
                                                        @click.prevent="updatePropertyType('PropertyTypeHabitat', 1)"
                                                    >
                                                        <b-card-body
                                                            class="d-flex"
                                                        >
                                                            <b-form-radio value="1"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Immeuble collectif`) }}</h6>
                                                                    <span class="text-muted">
                                                                    <rt-icon icon="building" variant='light' :size="21" class="mr-1"/>
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                                    <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': unitData.PropertyTypeHabitat =='2'}"
                                                        @click.prevent="updatePropertyType('PropertyTypeHabitat',2)"
                                                    >
                                                        <b-card-body class="d-flex">
                                                            <b-form-radio value="2"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Immeuble Individuel`) }}</h6>
                                                                    <div class="text-muted">
                                                                        <rt-icon icon="house" variant='light' :size="21" class="mr-1"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                            </b-form-radio-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Régime juridique de l’immeuble`)"
                                        >
                                                <!-- <b-form-checkbox
                                                    v-model="unitData.PropertyRegimeJuridique"
                                                    value="1"
                                                    class="mr-1"
                                                    button
                                                    button-variant="outline-secondary"
                                                >
                                                    {{ i18nT(`Copropriété`) }}
                                                </b-form-checkbox>
                                                <b-form-checkbox
                                                    v-model="unitData.PropertyRegimeJuridique"
                                                    value="2"
                                                    button
                                                    button-variant="outline-secondary"
                                                >
                                                    {{ i18nT(`Mono propriété`) }}
                                                </b-form-checkbox> -->
                                            <b-form-radio-group
                                                class="mt-1 pr-1 ren-radio-type-buttons d-flex flex-column flex-lg-row"
                                                v-model="unitData.PropertyRegimeJuridique"
                                            >
                                                    <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': unitData.PropertyRegimeJuridique =='1'}"
                                                        @click.prevent="updatePropertyType('PropertyRegimeJuridique', 1)"

                                                    >
                                                        <b-card-body
                                                            class="d-flex"
                                                        >
                                                            <b-form-radio value="1"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Copropriété`) }}</h6>
                                                                    <span class="text-muted">
                                                                    <rt-icon icon="user" variant='light' :size="21" class="mr-4"/>
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                                    <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': unitData.PropertyRegimeJuridique =='2'}"
                                                        @click.prevent="updatePropertyType('PropertyRegimeJuridique', 2)"
                                                    >
                                                        <b-card-body class="d-flex">
                                                            <b-form-radio value="2"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Mono propriété`) }}</h6>
                                                                    <span class="text-muted">
                                                                    <rt-icon icon="user-group" variant='light' :size="21" class="mr-1"/>
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Bien meublé`)"
                                        >
                                            <b-form-checkbox
                                                name="check-button"
                                                switch
                                                v-model='isFurnished'
                                                class="mb-50"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Fumeurs acceptés`)"
                                        >
                                            <b-form-checkbox
                                                name="check-button"
                                                switch
                                                v-model='isSmokingAllowed'
                                                class="mb-50"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Animaux acceptés`)"
                                        >
                                            <b-form-checkbox
                                                name="check-button"
                                                switch
                                                v-model='isPetAllowed'
                                                class="mb-50"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="my-1">
                                    <b-col cols="12" md="9">
                                        <b-form-group
                                            :label="i18nT(`Désignation des parties et équipements`)"
                                        >
                                            <b-form-checkbox
                                                v-for="(extra, index) in extras"
                                                :key="'extra-'+index"
                                                v-model="selectedExtras"
                                                :value="extra.value"
                                                inline
                                                class="rt-custom-checkbox ml-1 mt-1"
                                            >
                                                <div class="d-flex"><span class="mr-1">{{ i18nT(extra.text) }}</span>
                                                </div>
                                            </b-form-checkbox>
                                        </b-form-group>

                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Parking`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyParking"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Autres dépendances`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyOtherExpenses"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Cave`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyCave"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Lot`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyLot"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Millièmes`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyThousandths"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Références cadastrales`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row class="mt-3">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Feuille cadastrale`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyCadastreSheet"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Parcelle cadastrale`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyCadastrePart"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Catégorie cadastrale`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyCadastreCat"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Valeur locative cadastrale`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyCadastralIncome"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                FINANCIAL INFO-->

                <b-tab
                    @click="changeTab('financial-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="coins"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations financières`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Informations financières`)}}</h2>
                        </b-card-header>
                        <b-row class="mx-1 mb-2 pb-2 mt-1">
                            <b-col cols="12" md="12">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Date d'acquisition`)">
                                            <date-picker
                                                v-model="unitData.PropertyAcquisitionDate"
                                                :manual-input="true"
                                                reset-button
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Prix d’acquisition`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyInitialPrice"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="pt-50 mb-0">{{i18nT(`Montant utilisé pour calculer la rentabilité dans la rubrique Bilan.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Frais d'acquisition`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyAcquisitionCosts"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="pt-50 mb-0">{{i18nT(`Montant d'acquisition (frais de notaire, frais d'inscription etc.).`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Frais d'agence`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyAgencyCosts"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="pt-50 mb-0">{{i18nT(`Montant des frais d'agence.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Travaux de rénovation`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyRenovationCosts"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="pt-50 mb-0">{{i18nT(`Montant des travaux de rénovation.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Valeur actuelle`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyCurrentValue"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="pt-50 mb-0">{{i18nT(`Estimation de la valeur du marché de votre bien.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations fiscales`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row class="mt-3">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Régime fiscal`)"
                                        >
                                            <v-select
                                                v-model="unitData.PropertyTaxRegime"
                                                :options="taxRegimes"
                                                :clearable="false"
                                                :placeholder="i18nT(`Choisir`)"
                                                label="text"
                                                :reduce="(e) => +(e.value)"
                                                class="mb-50"
                                            />
                                            <small>{{i18nT(`Régime fiscal du bien.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Siret`)"
                                            >
                                            <b-form-input
                                                v-model="unitData.PropertySiret"
                                                class="mb-50"
                                            />
                                            <small>{{i18nT(`Numéro d'immatriculer votre activité attribué par l'administration fiscale.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Date de début d’activité`)"
                                        >
                                            <date-picker
                                                v-model="unitData.PropertyFirstActivityDate"
                                                :manual-input="true"
                                                reset-button
                                                class="mb-50"
                                            />
                                            <small>{{i18nT(`Date de première mise en location.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Numéro fiscal`)"
                                        >
                                            <b-form-input
                                                v-model="unitData.PropertyFiscalCode"
                                                class="mb-50"
                                            />
                                            <small>{{i18nT(`Numéro fiscal du bien attribué par l'administration fiscale.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Taxe d'habitation`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none pb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyTaxHabitation"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="mb-0">{{i18nT(`Montant repris à titre indicatif dans la fiche du bien.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Taxe foncière`)"
                                        >
                                            <b-input-group
                                                prepend="EUR"
                                                class="border-right-none pb-50"
                                            >
                                                <b-form-input
                                                    v-model="unitData.PropertyAnnualTax"
                                                    type="number"
                                                    class="w-50 border-left-none"
                                                    placeholder="0"
                                                />
                                            </b-input-group>
                                            <small class="mb-0">{{i18nT(`Montant repris à titre indicatif dans la fiche du bien.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Centre d'impôts`) }}</h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12" md="12">
                                <b-row class="mt-3">
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Nom du centre`)">
                                            <b-form-input
                                                v-model="unitData.PropertyTaxCenterName"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Adresse`)">
                                            <b-form-input
                                                v-model="unitData.PropertyTaxCenterAddress1"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Adresse 2`)">
                                            <b-form-input
                                                v-model="unitData.PropertyTaxCenterAddress2"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Code postal`)">
                                            <b-form-input
                                                v-model="unitData.PropertyTaxCenterPostalCode"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Ville`)">
                                            <b-form-input
                                                v-model="unitData.PropertyTaxCenterCity"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Notes`)"
                                        >
                                            <b-form-textarea
                                                id="notes"
                                                v-model="unitData.PropertyTaxCenterComments"
                                                :placeholder="i18nT(`Autres informations`)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-tab>

<!--                KEYS AND ACCESS CODES-->

                <b-tab
                    @click="changeTab('keys-and-access-codes')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="key"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Clés et digicode`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Clés et digicode`)}}</h2>
                        </b-card-header>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row :class="{'mt-1': index === 0}"
                                       clas="border-bottom"
                                       v-for="(code,index) in keysAndCodes"
                                       :key="'key+' + index"
                                       v-if="!code.PropertyKeyDelete"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-input
                                            v-model="code.PropertyKeyDescription"
                                            :placeholder="i18nT(`Description`)"
                                        />
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-input-group
                                            prepend="N°"
                                            class="border-right-none mb-50"
                                        >
                                            <b-form-input
                                                id="keyNumber"
                                                type="number"
                                                v-model="code.PropertyKeyNumber"
                                                :placeholder="i18nT(`Numéro`)"
                                            />
                                        </b-input-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-input
                                            id="quantity"
                                            type="number"
                                            v-model="code.PropertyKeyQuantity"
                                            :placeholder="i18nT(`Qté`)"
                                        />
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="4"
                                        class="mt-1"
                                    >
                                        <b-form-input
                                            id="comment"
                                            v-model="code.PropertyKeyComments"
                                            :placeholder="i18nT(`Commentaire`)"
                                            />
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeKeyAndCode(index)"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="addKeyAndCode"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter un élément`)}}
                                        </b-button>
                                        <p class="mt-50">{{i18nT(`Vous pouvez ajouter plusieurs clés et codes d'accès si besoin.`)}}</p>
                                    </b-col>

                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-tab>

<!--                CERTIFICATES AND CONTRACTS-->

                <b-tab
                    @click="changeTab('certificates-and-contracts')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="file-contract"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Contrats et Diagnostics`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Contrats et diagnostics`)}}</h2>
                        </b-card-header>
                        <b-row class="mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="pt-1"
                            >
                                <div v-for="(contract, index) in contracts"
                                     :key="'contract-'+index"
                                     class="py-1"
                                >
                                    <!--                                    TODO add editable prop when endpoint is ready-->
                                    <contract-card :contract="contract" :editable="true" @edit="onEditContract" :contractTypes="contractTypes"/>
                                </div>
                                <div class="pt-1 mb-1">
                                    <b-button
                                        variant="outline-primary"
                                        v-b-modal.modal-add-contract
                                    >
                                        <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                        {{i18nT(`Ajouter un document`)}}
                                    </b-button>
                                </div>
                                <small>{{i18nT(`Vous pouvez ajouter plusieurs contrats. Les fichiers seront sauvegardés dans la rubrique Documents.`)}}</small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-tab>

<!--                PROPERTY E-FLYER-->

                <b-tab
                    @click="changeTab('property-e-flyer')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="globe-pointer"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Flyer numérique`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Flyer numérique`)}}</h2>
                        </b-card-header>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row class="mt-2">
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Description publique`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Description`)"
                                            >
                                                <b-form-textarea
                                                    id="description"
                                                    v-model="unitData.PropertyPublicDescription"
                                                    rows="3"
                                                />
                                            </validation-provider>
                                            <p class="mt-50">{{ i18nT(`Description du bien immobilier reprise sur le flyer numérique.`) }}</p>
                                        </b-form-group>

                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Règlement intérieur`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Internal rules`)"
                                            >
                                                <b-form-textarea
                                                    id="internalRules"
                                                    v-model="unitData.PropertyHouseRules"
                                                    rows="3"
                                                />
                                            </validation-provider>
                                            <p class="mt-50">{{ i18nT(`Règlement intérieur repris sur le flyer numérique.`) }}</p>
                                        </b-form-group>

                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">

                                        <b-form-group :label="i18nT(`Adresse du flyer`)">
                                            <div
                                                @click.stop="copyUrl"
                                                class="mb-2 cursor-pointer"
                                            >
                                                <b-input-group>
                                                    <b-input-group-prepend is-text>
                                                        <rt-icon icon="link" variant="light"/>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        :value="propertyURL"
                                                        disabled
                                                        @click="openUrl"
                                                    >
                                                    </b-form-input>
                                                </b-input-group>
                                                <small>{{ i18nT(`Lien vers la fiche de votre bien à partager avec des tiers (ex. insérer dans vos annonces immobilières).`) }}</small>
                                            </div>
                                        </b-form-group>
                                    </b-col>

                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Disponibilité`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Availability`)"
                                            >
                                                <div class="d-flex">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublic'
                                                        class="mb-50"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                        {{ i18nT(`Le bien est `) }}
                                                        <strong>{{ propertyPublic ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                        <rt-icon variant="light" :icon="propertyPublic ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                            </validation-provider>
                                        </b-form-group>
                                        <small>{{ i18nT(`Activez le flyer électronique si vous êtes à la recherche de locataire. Les candidats pourraient postuler à votre annonce directement via notre site.`) }}</small>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Address`)"
                                            >
                                                <div class="d-flex">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublicAddress'
                                                        class="mb-50"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                        {{ i18nT(`L'adresse postale du bien est `) }}
                                                        <strong>{{ propertyPublicAddress ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                        <rt-icon variant="light" :icon="propertyPublicAddress ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Téléphone`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`eFlyerPhone`)"
                                            >
                                                <div class="d-flex">
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model='propertyPublicPhone'
                                                        class="mb-50"
                                                    >
                                                    </b-form-checkbox>
                                                    <span>
                                                        {{ i18nT(`Le numéro de téléphone est `) }}
                                                        <strong>{{ propertyPublicPhone ? i18nT(`Public `) :  i18nT(`Privé `)}}</strong>
                                                        <rt-icon variant="light" :icon="propertyPublicPhone ? 'eye' : 'eye-slash'"/>
                                                    </span>
                                                </div>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                PHOTOS-->

                <b-tab
                    @click="changeTab('photos')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="image"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Photos`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Photos`)}}</h2>
                        </b-card-header>
                        <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12"
                                md="12"
                                class="pt-1"
                            >
                                <vue-dropzone ref="photoDropzone"
                                    :duplicateCheck="true"
                                    @vdropzone-queue-complete="queCompleteMultiple"
                                    @vdropzone-error="dropzoneErrorMultiple"
                                    @vdropzone-success="dropzoneSuccessMultiple"
                                    id="dz-photos-multiple"
                                    class="ren-dropzone"
                                    :options="dropzoneOptions"
                                >
                                </vue-dropzone>
                                <draggable
                                    v-model="photos"
                                    tag="div"
                                    class="d-flex flex-wrap"
                                    @end="updateOrder"
                                >
                                    <div class="rt-img-container mt-2 mr-2 text-center"
                                         @click="openPreviewModal(photo)"
                                         v-for="(photo, index) in photos"
                                            :key="'Photo' + index"
                                    >
                                        <div class="rt-span"
                                             @click.stop="setCoverImage(photo,index)"
                                        >
                                            <span v-b-tooltip="i18nT(`Couverture`)">
                                                    <RtIcon :variant="photo.PropertyCoverImage ? 'solid' : 'regular'" icon="star rt-star-icon" :size="18" />
                                            </span>
                                        </div>
                                        <span class="rt-span" @click.stop="deletePhoto(photo,index)">
                                                    <RtIcon variant="light" icon="circle-xmark rt-xmark-icon text-danger"  :size="18"/>
                                        </span>
                                        <b-img :src="photo['image_thumb_url']" thumbnail :alt="'Photo' + index" class="rt-img" :class="{'rt-img-active': photo.PropertyCoverImage}"></b-img>
                                    </div>
                                </draggable>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                CONTACTS-->

                <b-tab
                    @click="changeTab('contacts')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="address-card"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Contacts`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Contacts`)}}</h2>
                        </b-card-header>
                        <b-row class="mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="pt-1"
                            >
                                <div v-for="(contact, index) in contacts"
                                     :key="'contact-'+index"
                                     class="py-1"
                                >
                                    <contact-card :contact="contact" :editable="true" @edit="onEditContact(contact,index)" @delete="onDeleteContact(contact,index)" />
                                </div>
                                <div class="pt-1 mb-1">
                                    <b-button
                                        variant="outline-primary"
                                        v-b-modal.modal-add-contact
                                    >
                                        <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                        {{i18nT(`Ajouter un contact`)}}
                                    </b-button>
                                </div>
                                <small>{{i18nT(`Vous pouvez ajouter plusieurs contacts si besoin. Chaque contact sera sauvegardé dans la rubrique carnet.`)}}</small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                DOCUMENTS-->

                <b-tab
                    @click="changeTab('documents')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="briefcase"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Documents`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Documents`)}}</h2>
                        </b-card-header>
                        <b-row class="mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="pt-1"
                            >
                                <div v-for="(document, index) in documents"
                                     :key="'document-'+index"
                                     class="py-1"
                                >
<!--                                    TODO add editable prop when endpoint is ready-->
                                    <document-card :document="document" @edit="onEditDocument(document,index)" :editable="document['_can_edit']" @delete="onDeleteDocument(document,index)"/>
                                </div>
                                <div class="pt-1 mb-1">
                                    <b-button
                                        variant="outline-primary"
                                        @click="openDocumentModal"
                                        >
                                        <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                        {{i18nT(`Ajouter un document`)}}
                                    </b-button>
                                </div>
                                <small>{{i18nT(`Vous pouvez ajouter plusieurs documents. Ces documents seront sauvegardés dans la rubrique Documents.`)}}</small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="unitData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ unitData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="unitData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ unitData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'properties'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
            </b-tabs>
        </validation-observer>

        <modal-add-document :documents="allDocuments" location="properties" :document-temp-id="documentTempId" @add="addDocument" />
        <modal-edit-document :document="propertyDocument"/>
        <modal-add-contact :contact="contact" @add="addContact" type="contact" location="properties"/>
        <modal-add-contract :contracts="contracts" :documents="allDocuments" :allContractTypes="contractTypes" :contract="contract" @reset="clearContract" />

        <b-modal
            id="preview"
            centered
            :hide-footer="true"
            size="lg"
        >
            <b-img
                :src="previewPhoto"
                alt="logo"
                class="mr-1"
                fluid-grow
            />
        </b-modal>

    </div>
</template>


<script>
import {
    BTabs,
    BCard,
    BTab,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BButton,
    VBTooltip, BBadge, BFormCheckbox, BImg, BDropdownItem, BDropdown,
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import { required } from '@validations'
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import ModalAddDocument from "@/views/common/Documents/ModalAddDocument.vue";
import ModalEditDocument from "@/views/common/Documents/ModalEditDocument.vue";
import ModalAddContact from "@/views/landlord/Tenants/ModalAddContact.vue";
import ModalAddContract from "@/views/common/Contracts/ModalAddContract.vue";
import ContractCard from "@/views/common/Contracts/ContractCard.vue";
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {API_KEY} from "@core/utils/constants";
import useAuth from '@/auth/useAuth';
import DatePicker from "@core/components/DatePicker.vue";
import draggable from 'vuedraggable'
import {dictToSelectArray} from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EllipsisDropdown from '@core/components/EllipsisDropdown.vue'
import {BASE_URL} from "@core/utils/constants";
import _ from "lodash";



export default{

    components:{
        BDropdown,
        BDropdownItem,
        BImg,
        BFormCheckbox,
        BBadge,
        DatePicker,
        ContractCard,
        BTabs,
        BCard,
        BTab,
        BCol,
        BRow,
        BButton,
        BFormInput,
        BFormGroup,
        DocumentCard,
        ContactCard,
        vSelect,
        EllipsisDropdown,
        ModalAddDocument,
        ModalAddContract,
        ModalAddContact,
        ModalEditDocument,
        ValidationObserver,
        ValidationProvider,
        VueDropzone: vue2Dropzone,
        draggable,
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data(){
        return{
            required,
            tabIndex: 0,
            tabNames:[
                'general-info',
                'additional-info',
                'financial-info',
                'keys-and-access-codes',
                'certificates-and-contracts',
                'property-e-flyer',
                'photos',
                'contacts',
                'documents',
            ],
            unitData: {},
            currency: {text: 'Euro', value: 'EUR'},
            currencyOptions: [],
            landlord: {},
            landlordOptions: [],
            type: {},
            typeOptions: [
                {text: "Apartment", value: 1},
                {text: "Atelier", value: 2},
                {text: "Boutique", value: 3}
            ],
            documents: [],
            countries: [],
            contracts: [],
            contacts: [],
            rentTypes: [],
            taxRegimes:[],
            extras: [],
            selectedExtras: [],
            contact: null,
            contract: null,
            contractTypes: [],
            propertyDocument: null,
            curDocumentIndex: null,
            keysAndCodes: [{}],
            previewPhoto: null,
            // photos: Array.from({length:50},(_, index)=> {
            //     return {
            //         PropertyImageID: index,
            //         PropertyImage: '',
            //         PropertyCoverImage: 0,
            //         PropertyImageOrder: index + 1,
            //         image_thumb_url: 'https://via.placeholder.com/150',
            //     }
            // }),
            photos: [],
            curUnitId: this.$route.params.id,
            dropzoneOptions: {
                // TODO add correct url once it's available //
                url: BASE_URL + 'landlord/properties/photo' + (this.$route.params.id ? `?PropertyID=${this.$route.params.id}` : '' ),
                addRemoveLinks: true,
                thumbnailWidth: 90,
                clickable: true,
                thumbnailMethod: 'contain',
                dictDefaultMessage: this.i18nT(`Déposez des fichiers ici ou cliquez pour charger.`),
                dictRemoveFile: this.i18nT(`Retirer`),
                dictFileTooBig: this.i18nT(`Le fichier est trop volumineux ({{filesize}} Mo). La taille maximale du fichier est : {{maxFilesize}} Mo`),
                dictInvalidFileType: this.i18nT(`Seuls les PDF et JPG sont autorisés`),
                dictCancelUpload: this.i18nT(`Annuler`),
                dictCancelUploadConfirmation: this.i18nT(`Êtes-vous sûr de vouloir annuler ce téléchargement?`),
                dictMaxFilesExceeded: this.i18nT(`Vous pouvez joindre jusqu'à 10 fichiers`),
                maxFilesize: 5,
                maxFiles: 10,
                parallelUploads: 1,
                uploadMultiple: false,
                paramName: 'PropertyImage',
                acceptedFiles: 'image/*,application/pdf',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },
            propertyPublic: false,
            propertyPublicAddress: false,
            propertyPublicPhone: false,
            isFurnished: false,
            isSmokingAllowed: false,
            isPetAllowed: false,
            propertyURL:'',
            curIndex: null,
            allDocuments: [],
            documentTempId: '',

        }
    },
    created() {
        let tab = this.$route.params.tab


        if(tab){
            this.tabIndex = this.tabNames.indexOf(tab)
        }

        this.$http.get('landlord/properties/new')
            .then(({data})=> {

                this.documentTempId = data.data.DocumentTempID;

                this.countries = data.data.countries.map(country => {
                    return {
                        value: country.id,
                        text: country.label,
                    }
                });

                this.currencyOptions = data.data.currencies.map(currency => {
                    return {
                        value: currency.id,
                        text: `${currency.id} - ${currency.label}`
                    }
                });

                this.typeOptions = data.data['property_types'].map(propertyType => ({value: propertyType.id, text: propertyType.label}));

                this.extras = data.data.extras.map(extra => ({value: extra.id, text: extra.label}));

                this.rentTypes = data.data['rent_types'].map(rentType => ({value: rentType.id, text: rentType.label}));

                this.taxRegimes = data.data['fiscal_regimes'].map(taxRegime => ({value: taxRegime.id, text: taxRegime.label}));

                this.landlordOptions = dictToSelectArray(data.data.landlords)

                this.allDocuments = data.data['all_documents'] || [];

                this.contractTypes = data.data['contract_types'].map(contractType => ({value: contractType.id, text: contractType.label}));

                this.propertyURL = `http://rentila2.loc:8009/properties/property/${this.curUnitId}` //TODO change to production url
        }).then(()=>{
            if(this.curUnitId){
                this.$http.get(`landlord/properties?id=${this.curUnitId}&edit_data=1`).then(({data})=>{
                    this.contracts = data.data['contracts'] || [];
                    this.unitData=data.data.property;

                    this.unitData.PropertyAvatarHexColor = data.data.property.PropertyAvatarHexColor ? `#${data.data.property.PropertyAvatarHexColor}`: '';


                    this.unitData.MLandlordID = this.unitData.MLandlordID == 0 ? this.unitData.LandlordID : this.unitData.MLandlordID;

                    this.unitData.PropertyTaxRegime =  this.unitData.PropertyTaxRegime || null;

                    this.propertyPublic = this.unitData.PropertyPublic ? true : false;

                    this.propertyPublicAddress = this.unitData.PropertyPublicAddress ? true : false;

                    this.propertyPublicPhone = this.unitData.PropertyPublicPhone ? true : false;

                    this.isFurnished = this.unitData.PropertyFurnished ? true : false;

                    this.isSmokingAllowed = this.unitData.PropertySmokers ? true : false;

                    this.isPetAllowed = this.unitData.PropertyAnimals ? true : false;

                    this.selectedExtras = data.data['property_extras'] || [];

                    this.documents = data.data['documents'] || [];

                    this.photos = this.unitData.PropertyImages || [];

                    this.keysAndCodes = data.data['keys_access_codes'] || [];

                    this.contacts = data.data['contacts'] || [];
                })
            }
        })


    },
    methods: {
        validationForm(){
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields}) => {
                if(isValid){
                    const formData = new FormData();
                    if(this.$route.params.id){
                        formData.append('id', this.$route.params.id)

                     } else {
                        formData.append('DocumentTempID', this.documentTempId);
                    }


                    //TODO propertyURL

                    // GENERAL INFO

                    formData.append('PropertyCurrency', this.unitData.PropertyCurrency ? this.unitData.PropertyCurrency : 'EUR');
                    formData.append('MLandlordID', this.unitData.MLandlordID !== this.unitData.LandlordID ? this.unitData.MLandlordID : 0);
                    formData.append('PropertyTypeID', this.unitData.PropertyTypeID);
                    formData.append('PropertyTitle', this.unitData.PropertyTitle);
                    formData.append('PropertyAvatarHexColor', this.unitData.PropertyAvatarHexColor ? this.unitData.PropertyAvatarHexColor.slice(1) : '');
                    formData.append('PropertyAddress', this.unitData.PropertyAddress);
                    formData.append('PropertyAddress2', this.unitData.PropertyAddress2);
                    formData.append('PropertyBatiment', this.unitData.PropertyBatiment);
                    formData.append('PropertyEscalier', this.unitData.PropertyEscalier);
                    formData.append('PropertyFloor', this.unitData.PropertyFloor);
                    formData.append('PropertyDoorNum', this.unitData.PropertyDoorNum);
                    formData.append('PropertyCity', this.unitData.PropertyCity);
                    formData.append('PropertyPostalCode', this.unitData.PropertyPostalCode);
                    formData.append('PropertyState', this.unitData.PropertyState);
                    formData.append('PropertyCountry', this.unitData.PropertyCountry);

                    formData.append('PropertyRentType', this.unitData.PropertyRentType);
                    formData.append('PropertyRent', this.unitData.PropertyRent);
                    formData.append('PropertyMaintenance', this.unitData.PropertyMaintenance);
                    formData.append('PropertySize', this.unitData.PropertySize);
                    formData.append('PropertyRoomsNum', this.unitData.PropertyRoomsNum);
                    formData.append('PropertyRoomsNumChambers', this.unitData.PropertyRoomsNumbChambers ? this.unitData.PropertyRoomsNumbChambers : 0);
                    formData.append('PropertyRoomsNumBaths', this.unitData.PropertyRoomsNumBaths ? this.unitData.PropertyRoomsNumBaths : 0);
                    formData.append('PropertyConstructionDate', this.unitData.PropertyConstructionDate ? this.unitData.PropertyConstructionDate : null);
                    formData.append('PropertyComments', this.unitData.PropertyComments);
                    formData.append('PropertyCommentsNew', this.unitData.PropertyCommentsNew);


                    //ADDITIONAL INFO

                    formData.append('PropertyTypeHabitat', this.unitData.PropertyTypeHabitat); //TODO Check values
                    formData.append('PropertyRegimeJuridique', this.unitData.PropertyRegimeJuridique); //TODO Check values
                    formData.append('PropertyFurnished', this.isFurnished ? 1 : 0);
                    formData.append('PropertySmokers', this.isSmokingAllowed ? 1 : 0);
                    formData.append('PropertyAnimals', this.isPetAllowed ? 1 : 0);

                    // EXTRAS
                    if(this.selectedExtras.length>0){
                        this.selectedExtras.forEach((extra) => {
                            formData.append(`PropertyExtras[]`, extra)
                        });
                    }

                    formData.append('PropertyParking', this.unitData.PropertyParking);
                    formData.append('PropertyOtherExpenses', this.unitData.PropertyOtherExpenses);
                    formData.append('PropertyCave', this.unitData.PropertyCave);
                    formData.append('PropertyLot', this.unitData.PropertyLot);
                    formData.append('PropertyThousandths', this.unitData.PropertyThousandths);
                    formData.append('PropertyCadastreSheet', this.unitData.PropertyCadastreSheet);
                    formData.append('PropertyCadastrePart', this.unitData.PropertyCadastrePart);
                    formData.append('PropertyCadastreCat', this.unitData.PropertyCadastreCat);
                    formData.append('PropertyCadastralIncome', this.unitData.PropertyCadastralIncome);

                    //FINANCIAL INFO


                    formData.append('PropertyAcquisitionDate', this.unitData.PropertyAcquisitionDate ? this.unitData.PropertyAcquisitionDate : null);
                    formData.append('PropertyInitialPrice', this.unitData.PropertyInitialPrice);
                    formData.append('PropertyAcquisitionCosts', this.unitData.PropertyAcquisitionCosts);
                    formData.append('PropertyAgencyCosts', this.unitData.PropertyAgencyCosts);
                    formData.append('PropertyRenovationCosts', this.unitData.PropertyRenovationCosts);
                    formData.append('PropertyCurrentValue', this.unitData.PropertyCurrentValue);
                    formData.append('PropertyTaxRegime', this.unitData.PropertyTaxRegime);
                    formData.append('PropertySiret', this.unitData.PropertySiret);
                    formData.append('PropertyFirstActivityDate', this.unitData.PropertyFirstActivityDate ? this.unitData.PropertyFirstActivityDate : null);
                    formData.append('PropertyFiscalCode', this.unitData.PropertyFiscalCode);
                    formData.append('PropertyTaxHabitation', this.unitData.PropertyTaxHabitation);
                    formData.append('PropertyAnnualTax', this.unitData.PropertyAnnualTax);
                    formData.append('PropertyTaxCenterName', this.unitData.PropertyTaxCenterName);
                    formData.append('PropertyTaxCenterAddress1', this.unitData.PropertyTaxCenterAddress1);
                    formData.append('PropertyTaxCenterAddress2', this.unitData.PropertyTaxCenterAddress2);
                    formData.append('PropertyTaxCenterPostalCode', this.unitData.PropertyTaxCenterPostalCode);
                    formData.append('PropertyTaxCenterCity', this.unitData.PropertyTaxCenterCity);
                    formData.append('PropertyTaxCenterComments', this.unitData.PropertyTaxCenterComments);


                    //E-FLYER

                    formData.append('PropertyPublicDescription', this.unitData.PropertyPublicDescription);
                    formData.append('PropertyHouseRules', this.unitData.PropertyHouseRules);
                    formData.append('PropertyPublic', this.propertyPublic ? 1 : 0);
                    formData.append('PropertyPublicAddress', this.propertyPublicAddress ? 1 : 0);
                    formData.append('PropertyPublicPhone', this.propertyPublicPhone ? 1 : 0);

                    //TODO KEYS AND ACCESS CODES

                    if(this.keysAndCodes.length>0){
                        this.keysAndCodes.forEach((key, index) => {

                            formData.append(`PropertyKeyID[${index}]`, key.PropertyKeyID ? key.PropertyKeyID : 0);
                            formData.append(`PropertyKeyDescription[${index}]`, key.PropertyKeyDescription)
                            formData.append(`PropertyKeyNumber[${index}]`, key.PropertyKeyNumber)
                            formData.append(`PropertyKeyQuantity[${index}]`, key.PropertyKeyQuantity)
                            formData.append(`PropertyKeyComments[${index}]`, key.PropertyKeyComments)

                            if(key.PropertyKeyDelete) {
                                formData.append(`PropertyKeyDelete[${index}]`, key.PropertyKeyDelete)
                            }
                        });
                    }

                    //TODO CERTIFICATES AND CONTRACTS

                    //TODO PHOTOS

                    //TODO CONTACTS

                    const contactIDs = this.contacts.map(contact=> contact.ContactID).join(',');
                    formData.append('ContactID', contactIDs);

                    //TODO DOCUMENTS

                    if(this.documents.length>0){
                        const documentIDs = this.documents.map(document=> document.DocumentID).join(',');
                        formData.append('DocumentID', documentIDs);
                    }



                    this.$http.post(`landlord/properties`, formData).then(({data})=> {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(data),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.$router.push({ name: 'properties' })
                    }).catch(ex => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(ex),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    })
                } else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            const el = document.getElementById(fields[key].id)
                            prevY = el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })
        },
        updatePropertyType(key, type) {
            this.unitData[key] = type  
            this.unitData = {...this.unitData}
        },
        changeTab(tab){

            this.tabIndex = this.tabNames.indexOf(tab)

            let currentPath

            if(!this.$route.params.id){
                currentPath='/landlord/properties/new'
            } else {
                currentPath=`/landlord/properties/${this.$route.params.id}/edit`
            }

            history.pushState({}, null,  currentPath+ "/" + encodeURIComponent(tab));
        },
        onDeleteDocument(document, index){
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirmer`),
                cancelButtonText: this.i18nT(`Annuler`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {

                    const id = this.curUnitId ? `PropertyID=${this.curUnitId}` : `DocumentTempID=${this.documentTempId}`;

                    if (!this.curUnitId && !document['_new_upload']) {
                        this.documents.splice(index, 1);
                        this.documents = [...this.documents];
                        this.curDocumentIndex=null;
                        return
                    }

                    this.$http.delete(`landlord/properties/document?${id}&DocumentID=${document.DocumentID}`).then(()=>{
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Succès!`),
                            text: this.i18nT(`Supprimé avec succès!`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })

                        this.documents.splice(index, 1);
                        this.documents = [...this.documents];
                        this.curDocumentIndex=null;
                    })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        onEditDocument(document, index){
            this.propertyDocument = document;
            this.curDocumentIndex = index;
            this.$bvModal.show('modal-edit-document')
        },
        onEditContact(contact, index){
            this.contact = {...contact};
            this.curIndex = index;
            this.$bvModal.show('modal-add-contact')
        },
        onDeleteContact(contact, index){
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirmer`),
                cancelButtonText: this.i18nT(`Annuler`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {
                    this.$http.delete(`landlord/properties/contact?property_id=${this.curUnitId}&contact_id=${contact.ContactID}`).then(()=>{
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Succès!`),
                            text: this.i18nT(`Supprimé avec succès!`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                            this.contacts.splice(index, 1);
                            this.contacts = [...this.contacts];
                    })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        onEditContract(contract){
            this.contract=contract;
            this.$bvModal.show('modal-add-contract')
        },
        clearContract(){
            this.contract = null;
        },
        addKeyAndCode() {
            this.keysAndCodes.push({})
        },
        removeKeyAndCode(index) {
            if(this.keysAndCodes[index].PropertyKeyID){
                this.keysAndCodes[index].PropertyKeyDelete = 1
                this.keysAndCodes = [...this.keysAndCodes];
            } else{
                this.keysAndCodes.splice(index, 1)
            }
        },
        onDelete(){
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`landlord/properties?id=${this.curUnitId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name: 'properties' })
                        })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        addContact(contact){
            if(this.curIndex != null){
                this.contacts[this.curIndex] = {...contact};
                this.contacts=[...this.contacts];
                this.curIndex =  null;
                return;
            }
            this.contacts.push(contact);
            this.curIndex = null;
        },
        queCompleteMultiple() {
            let self = this;

            setTimeout(function () {
                self.$refs.photoDropzone.removeAllFiles();
            }, 4000);
        },
        dropzoneErrorMultiple(message){
            console.log("message: ", message)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        dropzoneSuccessMultiple: function (files, response) {
            // TODO add to photos when api is ready
            // this.document.InvoiceDocuments.push({...response.data, AttachedFile: {DownloadUrl: response.data.DownloadUrl}});
            this.photos.push(response.data)
            console.log("photos: ", this.photos);
        },
        updateOrder(){

            let dataString;
            const formData = new FormData();
            if(this.curUnitId){
                dataString = this.photos.map(photo=> photo.PropertyImageID).join(',')
                formData.append('image_ids', dataString);
            } else {
                dataString = this.photos.map((photo)=> photo.index).join(',');
                formData.append('indexes', dataString)
            }

            const endPointString = this.curUnitId ? `positions?PropertyID=${this.curUnitId}` : 'temppositions';
            this.$http.post(`landlord/properties/${endPointString}`, formData, {showLoader:false}).then(({data})=>{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Succès!`),
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                if(!this.curUnitId) {
                    this.updatePhotoOrder()
                }
            }).catch(err=>{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        openPreviewModal(photo) {
            this.previewPhoto = photo['image_url']
            this.$bvModal.show('preview')
        },
        addDocument(newDocument){
            if(this.curDocumentIndex != null){
                this.documents[this.curDocumentIndex] = {...newDocument};
                this.documents=[...this.documents];
                this.curDocumentIndex =  null;
                return;
            }
            const checkExisting = this.documents.find(document => document.DocumentID === newDocument.DocumentID);
            if(checkExisting){
                const index = this.documents.indexOf(checkExisting);
                this.documents[index] = {...newDocument};
                this.documents=[...this.documents];
                return;
            }
            this.documents.push(newDocument);
            this.curDocumentIndex = null;
            this.propertyDocument = null;
        },
        openDocumentModal(){
            this.propertyDocument = null;
            this.curDocumentIndex = null;
            this.$bvModal.show('modal-add-document');
        },
        makeFavourite(i) {
            if(this.photos[i].isFavourite) {
                return
            } else {
                this.photos.forEach((photo, index) => {
                    if(photo.isFavourite && index !== i) {
                        photo.isFavourite = false
                    }
                    if(index === i) {
                        photo.isFavourite = true
                    }
                });
            }
        },
        copyUrl(){
            //NOTE: doesn't work on localhost

            navigator.clipboard.writeText(this.propertyURL)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    icon: 'InfoIcon',
                    title: `Url copied`,
                    variant: 'primary'
                }
            })
        },
        openUrl(){
            window.open(this.propertyURL, '_blank')
        },
        updatePhotoOrder(){
            this.photos = this.photos.map((photo, index) => {
                return {
                    ...photo,
                    // PropertyImagePos: index + 1,
                    index: index
                }
            })
        },
        setCoverImage(photo) {
            if(photo.PropertyCoverImage){
                return;
            }

            const endpointString = this.curUnitId ? `cover?CoverImageID=${photo.PropertyImageID}&PropertyID=${this.curUnitId}` : `tempcover?index=${photo.index}`;


            this.$http.post(`landlord/properties/${endpointString}`, null,{showLoader:false}).then(()=>{

                this.photos.forEach(savedPhoto=>savedPhoto.PropertyCoverImage=0);
                photo.PropertyCoverImage=1;
            })

        },
        deletePhoto(photo,index) {
            const isCoverImage = photo.PropertyCoverImage;
            const endpointString = this.curUnitId ? `photo?PropertyImageID=${photo.PropertyImageID}&PropertyID=${this.curUnitId}` : `tempphoto?index=${photo.index}`;


            this.$http.delete(`landlord/properties/${endpointString}`, {showLoader:false}).then(({data}) => {
                this.photos=data.data;
                if(isCoverImage){
                    this.setCoverImage(this.photos[0]);
                }
            });

            // if(this.photos[index].PropertyCoverImage) {
            //     this.photos.splice(index, 1)
            //     this.photos[0].PropertyCoverImage = 1
            // } else {
            //     this.photos.splice(index, 1)
            // }
        },
    }
}
</script>


<style lang="scss">

@media(min-width: 1500px){
    .ren-radio-type-buttons{
        max-width: 50%;
    }
}

.btn-outline-secondary:not(:disabled):not(.disabled).active {
    border-color: var(--primary) !important;
    background-color: var(--primary);
    color: #ffffff;
}

.dark-layout .btn-outline-secondary:not(:disabled):not(.disabled).active {
    border-color: var(--primary) !important;
    background-color: var(--primary);
    color: #d6d5d0 !important;
}

// Photos

.rt-img-container {
    position: relative !important;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    max-width: 250px;
    min-width: 150px;
    cursor: grab;
}

//.rt-img {
//    max-height: 200px !important;
//}

.rt-img-container:hover {
    transform: translateY(-2.5px);
}

.rt-span {
    height: 0px;
}

.rt-star-icon {
    position: absolute;
    top: 13px;
    right: 35%;
    color: var(--primary);
    cursor: pointer;
    background-color: rgba(255,255,255,0.8);
    border-radius: 50px;
    padding: 2px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.rt-img-container:hover .rt-star-icon {
    opacity: 1;
}

.rt-img-active.img-thumbnail  {
    border: 2px solid var(--primary) !important;
    /* box-shadow: rgba(var(--primary), 0.7) 0px 7px 29px 0px; */
}

.rt-xmark-icon {
    position: absolute;
    top: 13px;
    right: -35%;
    cursor: pointer;
    background-color: rgba(255,255,255,0.4);
    padding: 2px;
    border-radius: 50px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.rt-img-container:hover .rt-xmark-icon {
    opacity: 1;
}

.border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

.dark-layout .border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

.rt-custom-checkbox{
    min-width: 250px;
}

.ren-action-dropdown {
    margin-top: -20px;
    text-align: right;
}

.ren-action-dropdown .dropdown {
    margin-top: -50px;
}

.ren-action-btn {
    padding: 2px;
}
.rt-custom-checkbox{
    max-width: 150px;
}

.nav-item > a {
    color: inherit;
}

.nav-item > a:hover {
    color: inherit;
}


</style>

